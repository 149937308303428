import React, { useState } from 'react';
import css from './InputComponent.module.css';

const InputComponent = props => {
  const { onChange } = props;
  const [value, setValue] = useState('');

  const handleChange = event => {
    onChange(event);
    const inputValue = event.target.value;
    // Check if the input value meets the requirements
    if (inputValue.length <= 22 && !/[<>"'*]/.test(inputValue)) {
      setValue(inputValue);
    }
  };

  return <input type="text" value={value} onChange={handleChange} className={css.input} />;
};

export default InputComponent;
