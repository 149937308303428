import React from 'react';
import classNames from 'classnames';

import css from './StatusLabel.module.css';

export const StatusLabel = props => {
  const { status } = props;

  const pending = <div className={classNames(css.label, css.pendingLabel)}>Pending</div>;
  const paid = <div className={classNames(css.label, css.paidLabel)}>Paid</div>;
  const inTransit = <div className={classNames(css.label, css.inTransitLabel)}>In transit</div>;
  const failed = <div className={classNames(css.label, css.failedLabel)}>Failed</div>;

  let label;

  if (status === 'pending') {
    label = pending;
  }
  if (status === 'paid') {
    label = paid;
  }
  if (status === 'in_transit') {
    label = inTransit;
  }
  if (status === 'failed') {
    label = failed;
  }

  return <div className={css.container}>{label}</div>;
};
