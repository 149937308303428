import React, { useEffect, useState } from 'react';
import { compose } from 'redux';
import { connect, useDispatch, useSelector } from 'react-redux';
import { FormattedMessage, injectIntl } from '../../util/reactIntl';

import {
  LayoutSideNavigation,
  LayoutWrapperMain,
  LayoutWrapperAccountSideNav,
  LayoutWrapperTopbar,
  LayoutWrapperFooter,
  Footer,
  Page,
  IconSpinner,
  NamedLink,
  ExternalLink,
} from '../../components';
import { TopbarContainer } from '..';
import cardIcon from './icon-money-in.svg';
import successIcon from './success-icon.svg';
import failedIcon from './failed-icon.svg';
import crossIcon from './icon-cross.svg';
import iconInfo from './icon-info.svg';
import iconArrowLeft from './iocn-arrow-left.svg';
import iconArrowRight from './iocn-arrow-right.svg';
import cardIconLarge from './icon-money-in-large.svg';

import css from './WalletPage.module.css';
import { loadData } from './WalletPage.duck';
// import { getStripeBalance, initiatePayout } from '../../util/api';
import {
  createPayout,
  getUserBalance,
  getUserPayoutsList,
} from '../../ducks/stripeConnectAccount.duck';
import { StatusLabel } from './StatusLabel/StatusLabel';
import classNames from 'classnames';
import { useHistory } from 'react-router-dom/cjs/react-router-dom.min';
import { parse } from '../../util/urlHelpers';
import InputComponent from './InputComponent/InputComponent';

export const WalletPageComponent = props => {
  const { currentUser, scrollingDisabled, isProSessions } = props;

  const dispatch = useDispatch();

  const accountId = useSelector(
    state => state.stripeConnectAccount?.stripeAccount?.attributes?.stripeAccountId
  );

  // Payout result modal
  const [resModalOpen, setResModalOpen] = useState(false);

  const [failedModalOpen, setFailedModalOpen] = useState(false);

  const spinner = (
    <div className={css.spinnerContainer}>
      <IconSpinner />
    </div>
  );

  const successModal = (
    <div className={css.modalBackground} onClick={() => setResModalOpen(false)}>
      <div className={css.centered}>
        <div className={css.payoutResultModal} onClick={e => e.stopPropagation()}>
          <img
            className={css.closeIcon}
            onClick={() => setResModalOpen(false)}
            src={crossIcon}
            alt="close icon"
          />

          <div className={css.iconSuccessBackgroundCircle}>
            {' '}
            <img className={css.cardIcon} src={successIcon} alt="success icon" />
          </div>
          <div className={css.payoutResultModalText}>
            <p className={css.payoutResultModalHeader}>Payout sucess</p>
            <p className={css.payoutResultModalDescription}>
              Funds will arrive to your bank account within 1 business day.
            </p>
          </div>
        </div>
      </div>
    </div>
  );

  const failedModal = (
    <div className={css.modalBackground} onClick={() => setFailedModalOpen(false)}>
      <div className={css.centered}>
        <div
          className={classNames(css.payoutResultModal, css.payoutResultModalFailed)}
          onClick={e => e.stopPropagation()}
        >
          <img
            className={css.closeIcon}
            onClick={() => setFailedModalOpen(false)}
            src={crossIcon}
            alt="close icon"
          />
          <div className={css.iconFailedBackgroundCircle}>
            {' '}
            <img className={css.cardIcon} src={failedIcon} alt="failed icon" />
          </div>{' '}
          <div className={css.payoutResultModalText}>
            <p className={css.payoutResultModalHeader}>Payout failed</p>
            <p className={css.payoutResultModalDescription}>
              Please try again in a few minutes. If the issue persists, please contact our support
              team.
            </p>
          </div>
          <ExternalLink
            href="mailto:support@proled.app?subject=Payout failed"
            data-content="support@proled.app"
            data-type="mail"
            className={css.link}
          >
            <button className={css.contactButton}>Contact us</button>
          </ExternalLink>
        </div>
      </div>
    </div>
  );

  // Payout result modal end

  const [isModalOpen, setIsModalOpen] = useState(false);
  const [sum, setSum] = useState('');
  const [description, setDescription] = useState('');
  const [confirmChecked, setConfirmChecked] = useState(false);

  const [payoutModalError, setPayoutModalError] = useState('');

  const handleSumChange = event => {
    setPayoutModalError('');
    setSum(event.target.value);
  };

  const handleDescriptionChange = event => {
    setDescription(event.target.value);
  };

  const handleConfirmChange = event => {
    console.log(+sum, +(balance?.available[0]?.amount / 100).toFixed(2));
    console.log(+sum > +(balance?.available[0]?.amount / 100).toFixed(2));
    if (+sum > +(balance?.available[0]?.amount / 100).toFixed(2)) {
      setPayoutModalError('Amount cannot be greater than available balance amount');
    } else if (+sum < 1) {
      setPayoutModalError('Amount must be no less than $1.00');
    } else if (+sum === 0) {
      setPayoutModalError('Amount must be greater than 0');
    }
    setConfirmChecked(event.target.checked);
  };

  // Payout modal end

  const payoutRequestInProgress = useSelector(
    state => state.stripeConnectAccount?.payoutRequestInProgress
  );
  const payoutRequestError = useSelector(state => state.stripeConnectAccount?.payoutRequestError);

  // Payouts
  const payoutsList = useSelector(state => state.stripeConnectAccount?.payoutsList);

  const lastPayoutId = payoutsList?.data[payoutsList?.data?.length - 1]?.id;
  const firstPayoutId = payoutsList?.data[0]?.id;
  const history = useHistory();
  const urlParams = parse(history.location.search);

  const getPayoutListInProgress = useSelector(
    state => state.stripeConnectAccount?.getPayoutListInProgress
  );
  const getPayoutListError = useSelector(state => state.stripeConnectAccount?.getPayoutListError);
  const hasMorePayouts = payoutsList?.has_more;

  // Balance
  const balance = useSelector(state => state.stripeConnectAccount?.balance);
  const getStripeAccountBalanceInProgress = useSelector(
    state => state.stripeConnectAccount?.getStripeAccountBalanceInProgress
  );
  const getStripeAccountBalanceError = useSelector(
    state => state.stripeConnectAccount?.getStripeAccountBalanceError
  );
  const onCreatePayout = params => dispatch(createPayout(params));

  const resultModal = successModal;

  const handleSubmit = event => {
    event.preventDefault();
    if (confirmChecked) {
      // Perform form submission logic here
      setIsModalOpen(false);
      onCreatePayout({
        accountId,
        amount: sum * 100,
        currency: balance?.available[0]?.currency || 'sgd',
        descriptor: description,
      })
        .then(() => {
          setResModalOpen(true);
        })
        .catch(e => setFailedModalOpen(true));

      // dispatch(createPayout({ accountId, amount: sum, currency: 'sgd', descriptor: description }));
    }
  };

  useEffect(() => {
    if (payoutModalError) {
      setConfirmChecked(false);
      // setPayoutModalError('');
    }
  }, [confirmChecked]);
  useEffect(() => {
    if (accountId) {
      dispatch(getUserBalance({ accountId: accountId }));
      dispatch(
        getUserPayoutsList({
          accountId: accountId,
          starting_after: urlParams?.starting_after,
          ending_before: urlParams?.ending_before,
        })
      );
    }
  }, [resModalOpen, accountId]);

  useEffect(() => {
    if (isModalOpen || failedModalOpen || resModalOpen) {
      document.body.style.overflow = 'hidden';
    } else {
      document.body.style.overflow = 'inherit';
    }
  }, [isModalOpen, failedModalOpen, resModalOpen]);

  useEffect(() => {
    if (accountId) {
      dispatch(
        getUserPayoutsList({
          accountId: accountId,
          starting_after: urlParams?.starting_after,
          ending_before: urlParams?.ending_before,
        })
      );
    }
  }, [urlParams?.starting_after, urlParams?.ending_before]);

  const title = 'Wallet | ProLed';

  const stripeConnectAccountData = useSelector(
    state => state.stripeConnectAccount?.stripeAccount?.attributes?.stripeAccountData
  );

  const stripeUserFirstName = stripeConnectAccountData?.individual?.first_name;
  const stripeUserLastName = stripeConnectAccountData?.individual?.last_name;

  const getBankAccountLast4Digits = stripeAccountData =>
    stripeAccountData?.external_accounts?.data[0]?.last4 || null;

  const getBankName = stripeAccountData =>
    stripeAccountData?.external_accounts?.data[0]?.bank_name || null;

  return (
    <Page title={title} scrollingDisabled={scrollingDisabled}>
      <LayoutSideNavigation containerClassName={css.sidebarContainer}>
        <LayoutWrapperTopbar>
          <TopbarContainer
            currentPage="WalletPage"
            desktopClassName={css.desktopTopbar}
            mobileClassName={css.mobileTopbar}
          />
        </LayoutWrapperTopbar>
        <LayoutWrapperAccountSideNav
          currentTab="WalletPage"
          userProfile={true}
          isAvatar={true}
          currentUser={currentUser}
          isProUser={true}
        />
        <LayoutWrapperMain className={css.wrapperMain}>
          <div className={css.content}>
            {resModalOpen && resultModal}
            {failedModalOpen && failedModal}
            <h1 className={css.title}>
              <FormattedMessage id={'LayoutWrapperAccountSettingsSideNav.wallet'} />
            </h1>

            {getStripeAccountBalanceInProgress || !accountId ? (
              spinner
            ) : (
              <div className={css.balanceContainer}>
                <div className={css.leftBalance}>
                  <div className={css.balanceBar}>
                    <div className={css.iconWrapper}>
                      <img className={css.cardIcon} src={cardIcon} alt="card icon" />
                    </div>
                    <p className={css.balanceAmount}>
                      {(balance?.available[0]?.amount / 100).toFixed(2)}{' '}
                      {balance?.available[0]?.currency.toUpperCase()}
                    </p>
                  </div>
                  <div className={css.bottomInfo}>
                    <div className={css.description}>
                      <p className={css.descriptionHeader}>Available to payout</p>
                      <p className={css.descriptionBody}>
                        Funds that can be immediately transferred to your bank account.
                      </p>
                    </div>
                    <button className={css.payoutButton} onClick={() => setIsModalOpen(true)}>
                      Pay out to bank
                    </button>
                  </div>
                </div>
                <div className={css.rightBalance}>
                  <div className={css.balanceBar}>
                    <div className={css.iconWrapper}>
                      <img className={css.cardIcon} src={cardIcon} alt="card icon" />
                    </div>
                    <p className={css.balanceAmount}>
                      {(balance?.pending[0]?.amount / 100).toFixed(2)}{' '}
                      {balance?.pending[0]?.currency.toUpperCase()}
                    </p>
                  </div>
                  <div className={css.bottomInfo}>
                    <div className={css.description}>
                      <p className={css.descriptionHeader}>Processing</p>
                      <p className={css.descriptionBody}>
                        Funds will be available for payout within 3 - 7 business days.
                      </p>
                    </div>
                  </div>
                </div>
                {isModalOpen && (
                  <div className={css.modalBackground} onClick={() => setIsModalOpen(false)}>
                    <div className={css.centered}>
                      <img
                        className={css.closeIcon}
                        onClick={() => setResModalOpen(false)}
                        src={crossIcon}
                        alt="close icon"
                      />
                      <form
                        className={css.modal}
                        onSubmit={handleSubmit}
                        onClick={e => e.stopPropagation()}
                      >
                        <p className={css.modalHeader}>Pay out to bank</p>
                        <p className={css.inputLabel}>Amount</p>
                        <div className={css.inputContainer}>
                          <div className={css.currencyLabel}>
                            {balance?.available[0]?.currency.toUpperCase() || 'SGD'}
                          </div>
                          <input
                            className={classNames(css.modalInput, css.amountInput)}
                            type="number"
                            value={sum}
                            onChange={handleSumChange}
                          />
                        </div>
                        <p className={css.payoutModalError}>{payoutModalError}</p>
                        <p className={css.inputLabel}>Send to</p>
                        <p className={css.label}>
                          {getBankName(stripeConnectAccountData)} ****{' '}
                          {getBankAccountLast4Digits(stripeConnectAccountData)}
                        </p>
                        <p className={css.inputLabel}>Delivery method</p>
                        <p className={css.label}>Standard</p>
                        <p className={css.inputLabel}>Statement descriptor</p>
                        <div className={css.inputContainer}>
                          <InputComponent onChange={handleDescriptionChange} />
                          {/* <input
                            className={css.modalInput}
                            type="text"
                            value={description}
                            onChange={handleDescriptionChange}
                          />{' '} */}
                          <div className={css.infoIconContainer}>
                            <img className={css.infoIcon} src={iconInfo} alt="info icon" />
                            <span className={css.tooltiptext}>
                              {
                                "The statement descriptor needs to be fewer than 22 characters. It can't contain the chaaracters <, >, \", ', or *."
                              }
                            </span>
                          </div>
                        </div>
                        <div className={css.horizontalRuleModal} />
                        <div className={css.confirmationBar}>
                          <input
                            className={css.checkboxInput}
                            type="checkbox"
                            checked={confirmChecked}
                            onChange={handleConfirmChange}
                          />{' '}
                          <p className={css.confirmationText}>
                            Pay out SGD <span className={css.confirmAmount}>{sum}</span> from{' '}
                            {`${stripeUserFirstName} ${stripeUserLastName}`}
                            {stripeUserFirstName && "'s"} ProLed account balance to{' '}
                            <span className={css.confirmBank}>
                              {getBankName(stripeConnectAccountData)} ****{' '}
                              {getBankAccountLast4Digits(stripeConnectAccountData)}
                            </span>
                            .
                          </p>
                        </div>
                        <div className={css.buttonsContainer}>
                          <button
                            className={css.payoutModalButton}
                            onClick={() => setIsModalOpen(false)}
                          >
                            Cancel
                          </button>
                          <button
                            className={css.payoutModalButtonSubmit}
                            type="submit"
                            disabled={!confirmChecked}
                          >
                            {payoutRequestInProgress ? <IconSpinner /> : 'Pay out to bank'}
                          </button>
                        </div>
                      </form>
                    </div>
                  </div>
                )}
              </div>
            )}
            <div className={css.payoutsContainer}>
              <p className={css.payoutsHeader}>All payouts</p>
              {getPayoutListInProgress || !accountId ? (
                spinner
              ) : !!payoutsList?.data?.length ? (
                <div className={css.payoutsTable}>
                  <table className={css.tableContainer}>
                    <thead className={css.tableHeader}>
                      <tr>
                        <td className={css.cell}>
                          <div className={css.amountHeaderCell}>
                            <span>Amount</span>
                          </div>
                        </td>
                        <td className={css.cell}>
                          <div className={css.cellData}>
                            <span>Date</span>
                          </div>
                        </td>
                        <td className={css.cell}>
                          <div className={css.cellData}>
                            <span>Status</span>
                          </div>
                        </td>
                      </tr>
                    </thead>
                    {payoutsList?.data.map(item => {
                      const date = new Date(item.created * 1000);
                      const options = { month: 'short', day: 'numeric', year: 'numeric' };
                      const formattedDate = date.toLocaleDateString('en-US', options);

                      return (
                        <tr className={css.payoutRow}>
                          <td>
                            <div className={css.valueContainer}>
                              <span className={css.amountValue}>
                                {item.currency.toUpperCase()} {(item.amount / 100).toFixed(2)}
                              </span>
                            </div>
                          </td>
                          <td>
                            <div>
                              <span>{formattedDate}</span>
                            </div>
                          </td>
                          <td>
                            <div>
                              <StatusLabel status={item.status} />
                            </div>
                          </td>
                        </tr>
                      );
                    })}
                  </table>

                  <div className={css.tableButtonsContainer}>
                    <NamedLink
                      name="WalletPage"
                      to={{
                        search: `ending_before=${firstPayoutId}&page=${
                          urlParams.page > 1 ? urlParams.page - 1 : 1
                        }`,
                      }}
                    >
                      {' '}
                      <button
                        className={classNames(css.tableButton, css.leftTableButton)}
                        disabled={(!hasMorePayouts && urlParams.page === 1) || !urlParams.page}
                      >
                        {!((!hasMorePayouts && urlParams.page === 1) || !urlParams.page) && (
                          <img className={css.iconArrowLeft} src={iconArrowLeft} alt="left arrow" />
                        )}
                        Previous
                      </button>{' '}
                    </NamedLink>
                    <NamedLink
                      name="WalletPage"
                      to={{
                        search: `starting_after=${lastPayoutId}&page=${
                          urlParams.page ? urlParams.page + 1 : 2
                        }`,
                      }}
                    >
                      <button
                        className={classNames(css.tableButton, css.rightTableButton)}
                        disabled={!hasMorePayouts && urlParams.page !== 1}
                      >
                        Next
                        {!(!hasMorePayouts && urlParams.page !== 1) && (
                          <img
                            className={css.iconArrowRightt}
                            src={iconArrowRight}
                            alt="right arrow"
                          />
                        )}
                      </button>{' '}
                    </NamedLink>
                  </div>
                </div>
              ) : (
                <div className={css.noResultsBlock}>
                  <img className={css.cardIconLarge} src={cardIconLarge} alt="card" />
                  <p className={css.noResultsHeader}>No payouts yet</p>
                  <p className={css.noResultsSubtitle}>Keep track of your payouts here</p>
                </div>
              )}
            </div>
          </div>
        </LayoutWrapperMain>
        <LayoutWrapperFooter>
          <Footer />
        </LayoutWrapperFooter>
      </LayoutSideNavigation>
    </Page>
  );
};

const mapStateToProps = state => {
  // Topbar needs user info.
  const { currentUser } = state.user;

  return {
    currentUser,
  };
};

const WalletPage = compose(connect(mapStateToProps), injectIntl)(WalletPageComponent);

WalletPage.loadData = loadData;

export default WalletPage;
